// import Banner1 from "./images/banner1.png"
import Banner1 from "./images/banner1.jpg";
import PGF1 from "./images/PGF1.png";
import PGF2 from "./images/PGF2.png";
import PGF3 from "./images/PGF3.png";

import PGF4 from "./images/PGF4.jpg";
import PGF5 from "./images/PGF5.jpg";
import PGF6 from "./images/PGF6.jpg";
import PGF7 from "./images/PGF7.jpg";
import PGF8 from "./images/PGF8.jpg";

import Natarajan from "./images/Natarajan.jpeg";
import Naresh from "./images/Naresh.jpeg";
import AutoRaja from "./images/AutoRaja.jpeg";
import PrakashGantela from "./images/PrakashGantela.jpeg";
import Mangacharyulu from "./images/Mangacharyulu.png";
import Sudharanigaru from "./images/Sudharanigaru.png";

// 4th Annversday

import A4_1 from "./images/4thAnnversday/A4_1.webp";
import A4_2 from "./images/4thAnnversday/A4_2.webp";
import A4_3 from "./images/4thAnnversday/A4_3.webp";
import A4_4 from "./images/4thAnnversday/A4_4.webp";
import A4_5 from "./images/4thAnnversday/A4_5.webp";
import A4_6 from "./images/4thAnnversday/A4_6.webp";
import A4_7 from "./images/4thAnnversday/A4_7.webp";
import A4_8 from "./images/4thAnnversday/A4_8.webp";
import A4_9 from "./images/4thAnnversday/A4_9.webp";
// Church Fellowship

import CF_1 from "./images/churchFellowship/CF1.webp";
import CF_2 from "./images/churchFellowship/CF2.webp";

// Pre Christmas

import PC_1 from "./images/preChristmas23/PreChristmas2023_1.webp";
import PC_2 from "./images/preChristmas23/PreChristmas2023_2.webp";
import PC_3 from "./images/preChristmas23/PreChristmas2023_3.webp";
import PC_4 from "./images/preChristmas23/PreChristmas2023_4.webp";
import PC_5 from "./images/preChristmas23/PreChristmas2023_5.webp";
import PC_6 from "./images/preChristmas23/PreChristmas2023_6.webp";
import PC_7 from "./images/preChristmas23/PreChristmas2023_7.webp";
import PC_8 from "./images/preChristmas23/PreChristmas2023_8.webp";
import PC_9 from "./images/preChristmas23/PreChristmas2023_9.webp";
import PC_10 from "./images/preChristmas23/PreChristmas2023_10.webp";
import PC_11 from "./images/preChristmas23/PreChristmas2023_11.webp";

// Palm Sunday
import PS24_1 from "./images/palmSunday24/PS_24_1.webp";
import PS24_2 from "./images/palmSunday24/PS_24_2.webp";
import PS24_3 from "./images/palmSunday24/PS_24_3.webp";
import PS24_4 from "./images/palmSunday24/PS_24_4.webp";
import PS24_5 from "./images/palmSunday24/PS_24_5.webp";
import PS24_6 from "./images/palmSunday24/PS_24_6.webp";

// Easter 2024

import Easter24_1 from "./images/Easter24/Easter24_1.webp";
import Easter24_2 from "./images/Easter24/Easter24_2.webp";
import Easter24_3 from "./images/Easter24/Easter24_3.webp";
import Easter24_4 from "./images/Easter24/Easter24_4.webp";
import Easter24_5 from "./images/Easter24/Easter24_5.webp";
import Easter24_6 from "./images/Easter24/Easter24_6.webp";

// Christmas 2023

import Christmas23_1 from "./images/Christmas2023/Christmas23_1.webp";
import Christmas23_3 from "./images/Christmas2023/Christmas23_3.webp";
import Christmas23_4 from "./images/Christmas2023/Christmas23_4.webp";

// Church Events Intro

// VBS 24

import VBS1 from "./images/VBS24/VBS1.webp";
import VBS2 from "./images/VBS24/VBS2.webp";
import VBS3 from "./images/VBS24/VBS3.webp";
import VBS5 from "./images/VBS24/VBS5.webp";
import VBS6 from "./images/VBS24/VBS6.webp";
import VBS7 from "./images/VBS24/VBS7.webp";
import VBS8 from "./images/VBS24/VBS8.webp";
import VBS9 from "./images/VBS24/VBS9.webp";
import VBS11 from "./images/VBS24/VBS11.webp";
import VBS10 from "./images/VBS24/VBS10.webp";
import VBS12 from "./images/VBS24/VBS12.webp";
import VBS13 from "./images/VBS24/VBS13.webp";
import VBS14 from "./images/VBS24/VBS14.webp";
import VBS15 from "./images/VBS24/VBS15.webp";
import VBS16 from "./images/VBS24/VBS16.webp";
import VBS17 from "./images/VBS24/VBS17.webp";
import VBS18 from "./images/VBS24/VBS18.webp";
import VBS19 from "./images/VBS24/VBS19.webp";
import VBS20 from "./images/VBS24/VBS20.webp";
import VBS21 from "./images/VBS24/VBS21.webp";
import VBS22 from "./images/VBS24/VBS22.webp";
import VBS23 from "./images/VBS24/VBS23.webp";
import VBS24 from "./images/VBS24/VBS24.webp";
import VBS25 from "./images/VBS24/VBS25.webp";
import VBS26 from "./images/VBS24/VBS26.webp";
import VBS27 from "./images/VBS24/VBS27.webp";
import VBS28 from "./images/VBS24/VBS28.webp";

// New PGF babies

import Kid1 from "./images/PGF_BABIES/bhupal.webp";

// Palm Sunday rally

import PSR_1 from "./images/PalmSundayRally/PSR_1.webp";
import PSR_2 from "./images/PalmSundayRally/PSR_2.webp";
import PSR_3 from "./images/PalmSundayRally/PSR_3.webp";
import PSR_4 from "./images/PalmSundayRally/PSR_4.webp";
import PSR_5 from "./images/PalmSundayRally/PSR_5.webp";
import PSR_6 from "./images/PalmSundayRally/PSR_6.webp";
import PSR_7 from "./images/PalmSundayRally/PSR_7.webp";
import PSR_8 from "./images/PalmSundayRally/PSR_8.webp";
import PSR_9 from "./images/PalmSundayRally/PSR_9.webp";
import PSR_10 from "./images/PalmSundayRally/PSR_10.webp";
import PSR_11 from "./images/PalmSundayRally/PSR_11.webp";

// Sunday School Images

import SS1 from "./images/SundaySchool/SS1.webp";

// EVENTS INTRO IMAGES

export const ChurchEventImages = [PS24_1, A4_2, PC_11];
export const KidsEventImages = [PSR_7, VBS28, A4_4];

export const mainImages = [Banner1, PGF1, PGF2, PGF3];

export const oldImages = [PGF4, PGF5, PGF6, PGF7, PGF8];

export const ourServicesData = [
  {
    id: 1,
    title: "Church Fellowship",
    description:
      "Experience the warmth of community and deepen your spiritual journey at Church Fellowship, where hearts connect, and faith grows.",
    image:
      "https://i.swncdn.com/media/800w/via/5545-worship-song-singing-cross-church-gettyimages.jpg",

    path: "online-services",
  },
  {
    id: 2,
    title: "Connect with Us Online",
    description:
      "Connect with us virtually for an uplifting online service. Come together for worship, prayer, and encouragement.",
    image:
      "https://claytoncc.com/wp-content/uploads/2020/03/Join-us-online-for-church-3.png",

    path: "offline-services",
  },
  {
    id: 3,
    title: "Sunday School for Children",
    description:
      "Nurture your child's faith journey with our engaging Sunday School program. Through interactive lessons, activities, and stories, children discover the joy of God's love and learn biblical truths.",
    image:
      "https://static.wixstatic.com/media/47fdfe_c7a0a754ff8449cbb92a733fac207040~mv2_d_2800_1869_s_2.jpg/v1/fill/w_640,h_400,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/47fdfe_c7a0a754ff8449cbb92a733fac207040~mv2_d_2800_1869_s_2.jpg",

    path: "kids-corner",
  },
];
export const ourGuestSpeakers = [
  {
    id: 0,
    name: "Gantela Prakash garu",
    description: "Bible teacher and Christian apologist,Guntur",
    image: PrakashGantela,
    youtubeLink: "https://www.youtube.com/watch?v=cu_JtxTH4_o",
  },
  {
    id: 1,
    name: "P.Natarajan Lazarus garu",
    description: "",
    image: Natarajan,
    youtubeLink: "https://www.youtube.com/watch?v=9CNPytfefwI",
  },
  {
    id: 2,
    name: "John Mangacharyulu garu",
    description: "Holy Church , Pedalanka",
    image: Mangacharyulu,
    youtubeLink: "https://www.youtube.com/watch?v=P7dPQ2d-W6k",
  },
  {
    id: 21,
    name: "Sudharani garu",
    description: "",
    image: Sudharanigaru,
    youtubeLink: "https://www.youtube.com/watch?v=AJwEfwASqBU",
  },
  {
    id: 3,
    name: "Auto Raja garu",
    description: "New Ark Mission of India,Bangalore",
    image: AutoRaja,
    youtubeLink: "https://www.youtube.com/watch?v=P9fAi6MuWvw",
  },
  // {
  //   id: 4,
  //   name: "Sudharani garu",
  //   description: "Hyderabad",
  //   image:
  //     "https://i.swncdn.com/media/800w/via/5545-worship-song-singing-cross-church-gettyimages.jpg",
  //   youtubeLink: "",
  // },
  {
    id: 5,
    name: "Naresh garu",
    description: "",
    image: Naresh,
    youtubeLink: "https://www.youtube.com/watch?v=O3J1khimfPI",
  },
];
export const latestSermonsData = [
  {
    id: "112q1",
    youtubeLiveLink:
      "https://www.youtube.com/embed/c4pghohDMH4?si=-qA1LfPovMhTFyi0",
  },
  {
    id: "122q1",
    youtubeLiveLink:
      "https://www.youtube.com/embed/X9OM3VbncNM?si=Ia5mFhqJ2cMX98np",
  },
  {
    id: "12q1",
    youtubeLiveLink:
      "https://www.youtube.com/embed/5Cq-a9EzJrM?si=Jw3H-RKgZl0zDnqI",
  },
  {
    id: "121",
    youtubeLiveLink:
      "https://www.youtube.com/embed/dLLXjnuUuxY?si=wH3bDd29gejy0PVi",
  },

  {
    id: "01A",
    youtubeLiveLink:
      "https://www.youtube.com/embed/HPsC_VKpAmY?si=PbgHrv8W9DHp08_5",
  },
  {
    id: "01B",
    youtubeLiveLink:
      "https://www.youtube.com/embed/bUO5BkiL640?si=Hta7S8MFkH-D_0EP",
  },
  {
    id: "02A",
    youtubeLiveLink:
      "https://www.youtube.com/embed/Q_rdMCFQB6Y?si=JfVcGDukdrpbKq2T",
  },
  {
    id: "02B",
    youtubeLiveLink:
      "https://www.youtube.com/embed/QwyMpI8vTJc?si=RAxgxcvNk7sSKgrj",
  },
  {
    id: "03A",
    youtubeLiveLink:
      "https://www.youtube.com/embed/7mUJVUQuZxM?si=FpgvOLmH9pyJNTC6",
  },
  {
    id: "03B",
    youtubeLiveLink:
      "https://www.youtube.com/embed/sboig9ZkxRE?si=oqd9T4TpDPsEEbfx",
  },
  {
    id: "04A",
    youtubeLiveLink:
      "https://www.youtube.com/embed/7mUJVUQuZxM?si=FpgvOLmH9pyJNTC6",
  },
  {
    id: "04B",
    youtubeLiveLink:
      "https://www.youtube.com/embed/sboig9ZkxRE?si=3K6b38YwQs4AKs2O",
  },
];

// Events Data

export const churchEvents = [
  {
    id: 0,
    title: "PGF 4th Anniversary",
    images: [A4_1, A4_2, A4_3, A4_4, A4_5, A4_6, A4_7, A4_8, A4_9],
  },
  {
    id: 1,
    title: "Church Fellowship",
    images: [Easter24_1, CF_1, CF_2],
  },
  {
    id: 3,
    title: "Easter",
    images: [Easter24_2, Easter24_3, Easter24_4, Easter24_5, Easter24_6],
  },
  {
    id: 4,
    title: "Palm Sunday",
    images: [
      PSR_10,
      PSR_2,
      PSR_3,
      PSR_4,
      PSR_5,
      PSR_6,
      PSR_8,
      PSR_11,
      PS24_1,
      PS24_2,
      PS24_3,
      PS24_4,
      PS24_5,
      PS24_6,
    ],
  },
  {
    id: 6,
    title: "Christmas 2023",
    images: [Christmas23_1, Christmas23_3, Christmas23_4],
  },
  {
    id: 5,
    title: "Pre Christmas 2023",
    images: [
      PC_1,
      PC_2,
      PC_3,
      PC_4,
      PC_5,
      PC_6,
      PC_7,
      PC_8,
      PC_9,
      PC_10,
      PC_11,
    ],
  },
  {
    id: 2,
    title: "PGF",
    images: [PGF4, PGF5, PGF6, PGF7, PGF8],
  },
];

export const kidsEvents = [
  {
    id: 0,
    title: "VBS 2024 (Under His Wings)",
    images: [
      VBS1,
      VBS2,
      VBS3,
      VBS5,
      VBS6,
      VBS7,
      VBS8,
      VBS9,
      VBS10,
      VBS11,
      VBS12,
      VBS13,
      VBS14,
      VBS15,
      VBS16,
      VBS17,
      VBS18,
      VBS19,
      VBS20,
      VBS21,
      VBS22,
      VBS23,
      VBS24,
      VBS25,
      VBS26,
      VBS27,
    ],
  },
  {
    id: 1,
    title: "Sunday School",
    images: [SS1],
  },
  {
    id: 3,
    title: "Palm Sunday",
    images: [PSR_1, PSR_7, PSR_9],
  },
  {
    id: 2,
    title: "Blessings of New PGF babies",
    images: [Kid1],
  },
];
